import {config} from './config';

class Auth{
    constructor(sdk) {
      this.sdk = sdk;
      this.authenticated = this.checkAuthenticated();
    }
  
    async signup(email, password, name) {
      let promise = this.sdk.account.create(email, password, name);
      await promise.then(function (response) {
      }, function (error) {
          console.log(error); // Failure
          // window.location=config.baseUrl + '/failure'
      });
      setTimeout(() => {
        let promises = this.sdk.account.createSession(email, password);
  
        promises.then(function (response) {
            console.log(response); // Success
            // window.location=config.baseUrl + '/success'
        }, function (error) {
            alert(error); // Failure
            // window.location=config.baseUrl + '/failure'
        });
      }, 200)
    }
  
    login(email, password, callback) {
      let promise = this.sdk.account.createSession(email, password);
      promise.then(function (response) {
          // window.location=config.baseUrl + '/success'
          callback ? callback(true) : console.log(response);
      }, function (error) {
          // window.location=config.baseUrl + '/failure'
          callback ? callback(false) : console.log(error);
      });
    }

    logout() {
      let promise = this.sdk.account.deleteSessions();
  
      promise.then(function (response) {
        localStorage.removeItem('auth_state');
        console.log(response); // Success
        window.location=config.baseUrl + '/'
      }, function (error) {
        console.log('AUTH', error);
        console.log(error); // Failure
      });
    }
  
    checkAuthenticated() {
      const promise = this.sdk.account.getSessions();
      return promise.then(
          function(response) {
            localStorage.setItem('auth_state', 1);
            return response;
          },
          function(error) {
            localStorage.removeItem('auth_state');
            return null;
          }
      );
    }
  
    setAuthenticated(val) {
      this.authenticated = val;
    }
  
    checkLogin(callback) {
      this.sdk.account.get().then(function(response) {
        callback ? callback(true) : console.log(response);
      }, function(error) {
        callback ? callback(false) : console.log(error);
      })
    }
  
    getAuthenticated() {
      return this.checkAuthenticated();
    }
  }
  
  export default Auth;