export const config = {
    projectId: process.env.REACT_APP_PROJECT_ID,
    endpoint: process.env.REACT_APP_END_POINT,
    baseUrl: process.env.REACT_APP_BASE_URL
}

// const dummyData = [
//     {
//         name: "Super Admin",
//         desc: "R/W semua data"
//     },
//     {
//         name: "Direktur",
//         desc: "R/W semua data Stasiun"
//     },
//     {
//         name: "Kepala Stasiun",
//         desc: "R/W semua data Supervisi"
//     },
//     {
//         name: "Supervisi",
//         desc: "R/W semua data teknisi"
//     },
//     {
//         name: "Teknisi",
//         desc: "R/W semua data teknis seperti kamera dan sensor"
//     },
// ];
