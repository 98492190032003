import React from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.auth = props.auth;
    this.history = props.history;
    this.loading = props.loading;
    this.modal = props.modal;
  }

  goToDashboard = () => {
    this.history.push('/success');
  }

  callbackLogin = (state) => {
    if(state) 
      this.goToDashboard();
    else {
      this.loading(false);
      const content = {
        title: "Login Gagal",
        content: "Pastikan akun Anda valid.",
        color: "danger"
      }
      this.modal(true, content);
    }
  }

  handleLogin = (event) => {
    event.preventDefault();
    this.loading(true);
    var email = event.target.elements['email'].value;
    var password = event.target.elements['password'].value;
    this.auth.login(email, password, this.callbackLogin);
  }

  callbackCheckLogin = (state) => {
    if(state) {
      this.goToDashboard();
    } else {
      this.loading(false);
    }
  }

  componentDidMount() {
    this.auth.checkLogin(this.callbackCheckLogin);
  }

  render() {
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={this.handleLogin}>
                    <h3>Login</h3>
                    <p className="text-muted">Masuk ke Aplikasi Viana SS</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="email" name="email" placeholder="Email" autoComplete="email" required/>
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" name="password" placeholder="Password" autoComplete="current-password" required/>
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton type="submit" color="info" className="px-4">Login</CButton>
                      </CCol>
                      {/* <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0">Forgot password?</CButton>
                      </CCol> */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-info py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Viana SS</h2>
                    <p>VIANA makes AI-powered video analytics solutions that transform video from any camera source into actionable, tailored and trusted intelligence, enabling our customers to build and maintain the safesr and most productive organizations.</p>
                    {/* <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>Register Now!</CButton>
                    </Link> */}
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
    )
  }
}
