const glovar = {};

const errorHandler = (e) => {
    const modal = {
        title: "Terjadi Kesalahan" + (e.code ? (" dengan kode: " + e.code) : ""),
        content: e.message,
        color: 'danger'
    }
    glovar.loading(false);
    glovar.modal(true, modal);
}

export {glovar, errorHandler};