import React from 'react';
import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle  } from '@coreui/react'
import CIcon from '@coreui/icons-react';

export default class Modal extends React.Component{
    constructor(props) {
        super(props);
        this.content = props.content;
        this.modal = props.modal;
    }

    onClickButton(state) {
        if(this.content && this.content.callback) {
            this.content.callback(state);
        }
        this.modal(false);
    }

    render() {
        return (
        <div>
          <CModal 
              show={true}
              onClose={() => this.onClickButton(0)}
              color= {this.content.color ? this.content.color : "info"}
            >
              <CModalHeader closeButton>
                <CModalTitle>{this.content.title ? this.content.title : "Informasi"}</CModalTitle>
              </CModalHeader>
              <CModalBody>
                {this.content.content ? this.content.content : "Konten tidak tersedia. Pastikan mengisi key 'content'."}
              </CModalBody>
              <CModalFooter>
                <CButton color="secondary" onClick={() => this.onClickButton(0)}><CIcon name="cil-x" /></CButton>
                <CButton color={this.content.color ? this.content.color : "info"} onClick={() => this.onClickButton(1)}><CIcon name="cil-check" /></CButton>{' '}
              </CModalFooter>
            </CModal>
        </div>
        );
    }
}