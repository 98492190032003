import React from 'react';
// import { HashRouter as HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import './scss/style.scss';

import * as Appwrite from 'appwrite';

import Login from './modules/Login/Login';
import Success from './modules/Login/Success';
import Failure from './modules/Login/Failure';

import Auth from './Auth';

import {config} from './config';

import TheLoading from './containers/loading';
import TheModal from './containers/modal';

import { glovar } from "service.js";

const appwrite = new Appwrite();

appwrite
    .setEndpoint(config.endpoint)
    .setProject(config.projectId);

const auth = new Auth(appwrite);

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isLoading: true, isModal: false, contenModal:{}};
    this.loading = this.loading.bind(this);
    this.modal = this.modal.bind(this);
  }

  loading(state) {
    this.setState({isLoading:state});
  }

  modal(state, content) {
    if(state)
      this.setState({isModal: state, contenModal: content});
    else
    this.setState({isModal: false, contenModal: {}});
  }

  componentDidMount() {
    glovar['loading'] = this.loading;
    glovar['modal'] = this.modal;
  }

  render() {
    return (
      <HashRouter>
      { this.state.isLoading ? (<TheLoading />) : (<div></div>)}
      { this.state.isModal ? (<TheModal content={this.state.contenModal} modal={this.modal} />) : (<div></div>)}
        <React.Suspense fallback={<TheLoading />}>
          <Switch>
            <Route exact path="/signin" render={(props) => <Login {...props} auth={auth} loading={this.loading}  modal={this.modal} />} />
            <Route exact path="/failure" render ={(props)=> <Failure {...props} auth={auth}  />} />
            <Route exact path="/success" render ={(props)=> <Success {...props} auth={auth} />} />
            {/* <Route path="/" name="Dashboard" render={(props) => localStorage.getItem('auth_state') ? <Home {...props} auth={auth} /> : <Redirect to='/signin' />} /> */}
            <Route path="/" name="Dashboard" render={(props) => localStorage.getItem('auth_state') ? <TheLayout {...props} auth={auth} loading={this.loading} modal={this.modal} /> : <Redirect to='/signin' />} />
            {/* <Route path="/" name="Dashboard" render={(props) => <TheLayout {...props} auth={auth} />} /> */}
          </Switch>
        </React.Suspense>
      </HashRouter>
  );
  }
}
